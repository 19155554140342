import "bootstrap/dist/css/bootstrap.css"
import 'bootstrap-icons/font/bootstrap-icons.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast, { TYPE } from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueSplide from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
import { Tabs, Tab } from 'vue3-tabs-component';
import { useToast } from 'vue-toastification'

const toastOptions = {
    draggable: false,
    transition: "Vue-Toastification__bounce",
    maxToasts: 20,
    newestOnTop: true,
    [TYPE.ERROR]: {
        timeout: 3000,
    },
    [TYPE.SUCCESS]: {
        timeout: 3000,
        hideProgressBar: true,
    }
}

const toast = useToast()

const app = createApp(App)
    .use(store)
    .use(router)
    .use(Toast, toastOptions)
    .use(VueSplide)
    .component('tabs', Tabs)
    .component('tab', Tab)

app.mount('#app')

app.config.errorHandler = (err, instance, info) => {
    toast.error(err.name)
    console.log(err)
    console.log(instance)
    console.log(info)
}

import "bootstrap/dist/js/bootstrap.js"