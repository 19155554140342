import axios from 'axios'
import store from './store'
import router from './router'
import { useToast } from 'vue-toastification'

const toast = useToast()
const loginConfig = {
    baseURL: window.VUE_APP_API_URL || process.env.VUE_APP_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
}
export const LoginAPIInstance = axios.create(loginConfig)

const defaultConfig = {
    baseURL: window.VUE_APP_API_URL || process.env.VUE_APP_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
}

export const DefaultAPIInstance = axios.create(defaultConfig)

DefaultAPIInstance.interceptors.response.use(response => {
    if (response.data && !response.data.success && response.data.errors.length) {
        response.data.errors.forEach((x) => {
            if (x.type == 'ServerLogicError') {
                toast.error(x.details)
            }
            else {
                toast.error(x.message)
            }
        })
        return Promise.reject(response)
    }
    return response
}, err => {
    if (err.response && (err.response.status === 401 || err.response.status === 500)) {
        if (err.response.data.errors.length) {
            err.response.data.errors.forEach((x) => {
                toast.error(x.message);
            });
        }
    } else {
        console.error(err);
        if (err.code === "ERR_NETWORK") {
            toast.error("Сервер не доступен");
        } else if (
            err.response.status === 400 &&
            err.response.data.error.type === "InvalidToken"
        ) {
            toast.error("Ошибка авторизации");
            store.dispatch("authModule/onLogout");
            router.push("/login");
        } else {
            if (err.response.data.errors.length) {
                err.response.data.errors.forEach((x) => {
                    toast.error(x.message);
                });
            }
        }
    }
    return Promise.reject(err)
})