import { LoginAPIInstance, DefaultAPIInstance } from "@/apiInstances";
let loginAbortController
export const AuthAPI = {

    login(authToken) {
        loginAbortController = new AbortController()
        const url = "/api/v1/mobile-device-token-auth"
        return LoginAPIInstance.post(url, { authToken: authToken }, {
            signal: loginAbortController.signal
        })
    },
    getDeviceRights() {
        const url = "/api/v1/mobile-device/design-monitoring/mobile-device-rights"
        return DefaultAPIInstance.post(url, {})
    },
    logout() {
        const url = "/logout"
        return DefaultAPIInstance.post(url)
    },
    cancelRequest() {
        return loginAbortController.abort()
    }
}

