import { createStore } from 'vuex'
import { AuthModule } from './modules/AuthModule'
import { RequestDataModule } from './modules/RequestDataModule'
import { QRScannerModule } from './modules/QRScannerModule'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    requestDataModule: RequestDataModule,
    authModule:  AuthModule,
    qrScannerModule: QRScannerModule
  }
})
