import { DefaultAPIInstance } from "@/apiInstances"
import { AuthAPI } from "@/authAPI"
import router from "@/router"

export const AuthModule = {
    namespaced: true,

    state() {
        return {
            credentials: {
                token: localStorage.getItem('token') || null,
                userRole: localStorage.getItem('userRole') || 'isGuest',
                allowControlPanel: localStorage.getItem('allowControlPanel') === 'true',
                allowQR: localStorage.getItem('allowQR') === 'true',
                lastPath: localStorage.getItem('lastPath'),
                isAutharized: false
            },
            deviceId: null,
        }
    },

    getters: {
        getUserRole: (state) => state.credentials.userRole,
        isAutharized: (state) => state.credentials.isAutharized,
        getToken: (state) => state.credentials.token,
        getDeviceId: (state) => state.deviceId,
        getAllowControlPanel: (state) => state.credentials.allowControlPanel,
        getAllowQR: (state) => state.credentials.allowQR,
        getLastPath: (state) => state.credentials.lastPath,
    },

    mutations: {
        setToken(state, token) {
            state.credentials.token = token
            localStorage.setItem('token', token)
        },
        setDeviceId(state, deviceId) {
            state.deviceId = deviceId
        },
        setIsAutharized(state, isAutharized) {
            state.credentials.isAutharized = isAutharized
        },
        setUserRole(state, userRole) {
            state.credentials.userRole = userRole
            localStorage.setItem('userRole', userRole)
        },
        setAllowControlPanel(state, allowControlPanel) {
            state.credentials.allowControlPanel = allowControlPanel
            localStorage.setItem('allowControlPanel', allowControlPanel)
        },
        setAllowQR(state, allowQR) {
            state.credentials.allowQR = allowQR
            localStorage.setItem('allowQR', allowQR)
        },
        updateLastPath(state, lastPath) {
            state.credentials.lastPath = lastPath
            localStorage.setItem('lastPath', lastPath)
        },
        reloadLastPath(state) {
            if (localStorage.getItem('lastPath')) {
                state.credentials.lastPath = localStorage.getItem('lastPath')
            }
            else {
                state.credentials.lastPath = null
            }
        },
        deleteToken(state) {
            state.credentials.token = null
            localStorage.removeItem('token')
        },
        deleteUserRole(state) {
            state.credentials.userRole = null
            localStorage.removeItem('userRole')
        },
        deleteDeviceId(state) {
            state.deviceId = null
            localStorage.removeItem('deviceId')
        },
        deleteAllowControlPanel(state) {
            state.credentials.allowControlPanel = false
            localStorage.removeItem('allowControlPanel')
        },
        deleteAllowQR(state) {
            state.credentials.allowQR = false
            localStorage.removeItem('allowQR')
        },
        deleteLastPath(state) {
            state.credentials.lastPath = '/'
            localStorage.removeItem('lastPath')
        },
        checkToken(state) {
            if (state.credentials.token != null) {
                DefaultAPIInstance.defaults.headers['Authorization'] = `Bearer  ${state.credentials.token}`
            }
        }
    },

    actions: {
        onLogin({ commit }, authToken) {
            return AuthAPI.login(authToken).then(res => {
                if (res && res.code === "ERR_CANCELED") {
                    return res.code
                }
                if (res.data.success && !res.data.timeout) {
                    commit('setToken', res.data.response.token)
                    commit('setUserRole', 'isAuthorized')
                    commit('setIsAutharized', true)
                    DefaultAPIInstance.defaults.headers['Authorization'] = `Bearer  ${res.data.response.token}`
                }
                return res.data
            })
                .catch(res => {
                    console.error(res)
                    throw res.response || res
                })
        },
        setLastPath({ commit }, lastPath) {
            commit('updateLastPath', lastPath)
        },
        deleteLastPath({ commit }) {
            commit('deleteLastPath')
        },
        getDeviceRights({ commit, getters }) {
            return AuthAPI.getDeviceRights().then(res => {
                if (res && res.code === "ERR_CANCELED") {
                    return res.code
                }
                commit('reloadLastPath')
                if (res.data.success && !res.data.timeout) {
                    commit('setAllowControlPanel', res.data.response.allowControlPanel)
                    commit('setAllowQR', res.data.response.allowScanQR)
                    commit('setDeviceId', res.data.response.deviceId)
                }
                if (getters.getLastPath && !router.getRoutes().map(x => x.path).includes(getters.getLastPath)) {
                    commit("updateLastPath", "/")
                }
                return res.data
            })
                .catch(res => {
                    console.error(res)
                    throw res.response || res
                })
        },
        async checkToken({ commit, getters }) {
            commit('checkToken', null)
            let needLogout = false
            let notConnected = false
            if (getters.getToken) {
                await AuthAPI.getDeviceRights().then(res => {
                    if (res && res.code === "ERR_CANCELED") {
                        needLogout = true
                    }
                    else if (res.data.success && !res.data.timeout) {
                        commit('setAllowControlPanel', res.data.response.allowControlPanel)
                        commit('setAllowQR', res.data.response.allowScanQR)
                        commit('setDeviceId', res.data.response.deviceId)
                        needLogout = false
                    } else {
                        needLogout = true
                    }
                })
                    .catch((res) => {
                        if (res && res.code === "ERR_NETWORK") {
                            notConnected = true
                            needLogout = false
                        }
                        else {
                            needLogout = true
                        }
                    })
            }
            else {
                needLogout = true
            }
            if (needLogout) {
                commit('deleteToken', null)
                commit('deleteUserRole', null)
                commit('deleteAllowControlPanel', null)
                commit('deleteAllowQR', null)
                commit('setIsAutharized', false)
                commit('deleteDeviceId', null)
                delete DefaultAPIInstance.defaults.headers['Authorization']
            }
            else {
                commit('setIsAutharized', true)
                if (getters["authModule/getLastPath"]) {
                    router.push(
                        this.$store.getters["authModule/getLastPath"]
                    )
                } else {
                    router.push('/')
                }
            }
            return new Promise(resolve => {
                if (getters.getToken != null
                    && !getters.getAllowControlPanel
                    && !getters.getAllowQR
                    && getters.isAutharized) {
                    resolve("BAD_ACCESS");
                }
                else if (notConnected) {
                    resolve("notConnected")
                }
                else {
                    resolve("OK")
                }
            })
        },
        onLogout({ commit }) {
            commit('deleteToken', null)
            commit('deleteUserRole', null)
            commit('deleteAllowControlPanel', null)
            commit('deleteAllowQR', null)
            commit('setIsAutharized', false)
            commit('deleteDeviceId', null)
            delete DefaultAPIInstance.defaults.headers['Authorization']
        },
        cancelRequest() {
            return AuthAPI.cancelRequest()
        }
    },
}