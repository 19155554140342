<template>
    <div class="logo">
        <a href="/">
            <img src="/resources/img/logo-login.svg" alt="" />
        </a>
    </div>
    <div class="form-wrapper position-relative">
        <form id="sign-in" class="form-signin active" @submit.prevent="login">
            <h1>Авторизация</h1>
            <h3>Пожалуйста, введите код авторизации</h3>
            <div class="signin-body">
                <div class="form-group position-relative">
                    <input
                        class="login-input"
                        required
                        type="password"
                        :disabled="autAttempt"
                        placeholder="Укажите код авторизации"
                        v-model="authToken"
                        name="authToken"
                    />
                    <button
                        class="btn btn-info my-1 scan-login-btn p-0"
                        type="button"
                        title="Сканировать QR"
                        @click="scanQR"
                    >
                        <i class="bi bi-qr-code-scan d-block mb-0"></i>
                    </button>
                </div>
                <div class="form-group">
                    <button type="submit" v-if="!autAttempt" class="btn btn-warning login-btn">Войти</button>
                    <button v-else class="btn btn-info" type="button" @click="cancelLogin">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Отменить (Загрузка...{{ autAttemptCounter }})
                    </button>
                </div>
            </div>
        </form>
        <div v-if="needScanAuthToken" class="qr-code-scanner-container">
            <qrcode-stream
                :track="paintBoundingBox"
                :paused="paused"
                @detect="onDetect"
                @camera-on="resetValidationState"
                :constraints="{ facingMode }" 
                @error="onError"
            >
                <div
                    v-if="validationPending && camIsOn"
                    class="validation-pending d-flex flex-row justify-content-center align-items-center"
                >
                    <span class="h1 bg-success text-white p-2">Обработка QR кода</span>
                </div>
                <div>
                    <button
                        class="btn btn-light my-1 scan-login-btn p-0"
                        type="button"
                        @click="cancelScanQR"
                        title="Закрыть"
                    >
                        <i class="bi bi-x-square-fill"></i>
                    </button>
                    <button
                        class="btn btn-light my-1 scan-login-btn p-0 me-5"
                        type="button"
                        @click="switchCam"
                        title="Сменить камеру"
                    >
                        <i class="bi bi-arrow-repeat"></i>
                    </button>
                </div>
            </qrcode-stream>
        </div>
        <div class="form-footer">
            <span>ООО «СВЕТОСИСТЕМЫ»</span>
        </div>
    </div>
    <div v-if="choiceActiive" class="custom-choice-modal" id="custom-modal">
        <div class="custom-modal-container container py-4">
            <button class="btn btn-info mx-3 main-choice-btn" type="button" @click="changeRoute('/')">
                <i class="bi bi-ui-checks h1 d-block"></i>
                <span class="h6 text-white">Панель управления освещением</span>
            </button>
            <button class="btn btn-info mx-3 main-choice-btn" type="button" @click="changeRoute('/qr')">
                <i class="bi bi-qr-code-scan h1 d-block"></i>
                <span class="h6 text-white">Сканирование QR</span>
            </button>
        </div>
    </div>
</template>
<script>
import {useToast} from "vue-toastification"
import {QrcodeStream} from "vue-qrcode-reader"
export default {
    name: "Login",
    components: {
        QrcodeStream,
    },
    setup() {
        const toast = useToast()
        return {toast}
    },
    data() {
        return {
            authToken: "",
            autAttempt: false,
            autAttemptCounter: 0,
            choiceActiive: false,
            camIsOn: false,
            paused: false,
            qrKey: null,
            isValid: undefined,
            needScanAuthToken: false,
            facingMode: 'environment',  
            noRearCamera: false,
            noFrontCamera: false
        }
    },
    computed: {
        validationPending() {
            return this.isValid === undefined && this.paused == true
        },
    },
    methods: {
        cancelScanQR() {
            this.needScanAuthToken = false
            this.turnCameraOff()
        },
        async switchCam(){
            this.turnCameraOff()
            switch (this.facingMode) {
                case 'environment':
                this.facingMode = 'user'
                break
                case 'user':
                this.facingMode = 'environment'
                break
            }
            await this.timeout(300)
            this.turnCameraOn()
        },
        onError(error) {
            const triedFrontCamera = this.facingMode === 'user'
            const triedRearCamera = this.facingMode === 'environment'

            const cameraMissingError = error.name === 'OverconstrainedError'

            if (triedRearCamera && cameraMissingError) {
                this.noRearCamera = true
            }

            if (triedFrontCamera && cameraMissingError) {
                this.noFrontCamera = true
            }

            console.error(error)
        },
        scanQR() {
            this.needScanAuthToken = true
            this.turnCameraOn()
        },
        resetValidationState() {
            this.isValid = undefined
        },
        timeout(ms) {
            return new Promise((resolve) => {
                window.setTimeout(resolve, ms)
            })
        },
        async onDetect(content) {
            let scanVal = content[0].rawValue
            this.turnCameraOff()
            this.needScanAuthToken = false
            let urlData = this.getTokenFromUrl(scanVal)
            if (urlData) {
                this.authToken = urlData
            } else {
                this.authToken = scanVal
            }
            this.login()
        },
        getTokenFromUrl(dirtyUrl) {
            if (!dirtyUrl) {
                return null
            }
            try {
                let url = new URL(dirtyUrl)
                if (window.VUE_APP_LOGIN_HOST_URL !== url.host) return null
                if (url.pathname !== "/login") return null
                if (url.searchParams.get("token")) {
                    return url.searchParams.get("token")
                }
                return null
            } catch (e) {
                console.error(e)
                return null
            }
        },
        toggleCamera() {
            this.camIsOn = !this.camIsOn
            if (!this.camIsOn) {
                this.turnCameraOff()
            } else {
                this.turnCameraOn()
            }
        },
        turnCameraOn() {
            this.paused = false
        },

        turnCameraOff() {
            this.paused = true
        },

        paintBoundingBox(detectedCodes, ctx) {
            for (const detectedCode of detectedCodes) {
                const {
                    boundingBox: {x, y, width, height},
                } = detectedCode

                ctx.lineWidth = 2
                ctx.strokeStyle = "#007bff"
                ctx.strokeRect(x, y, width, height)
            }
        },
        changeRoute(routePath) {
            this.$store.dispatch("authModule/setLastPath", routePath)
            this.$router.push(routePath)
        },
        login() {
            this.toast.clear()
            this.autAttempt = true
            this.autAttemptCounter++
            this.$store
                .dispatch("authModule/onLogin", this.authToken)
                .then((resp) => {
                    if (resp && resp.code === "ERR_CANCELED") {
                        this.resetParams()
                    } else {
                        if (this.$store.getters["authModule/isAutharized"]) {
                            this.resetParams()
                            this.getDeviceRights()
                        } else {
                            this.login()
                        }
                    }
                })
                .catch((err) => {
                    if (err.status === 401 || err.status === 500) {
                        if (err.data.errors.length) {
                            err.data.errors.forEach((x) => {
                                this.toast.error(x.message)
                            })
                        }
                    } else {
                        console.error(err)
                        if (err.code === "ERR_NETWORK") {
                            this.toast.error("Сервер не доступен")
                        }
                    }
                    this.resetParams()
                })
        },
        getDeviceRights() {
            this.$store
                .dispatch("authModule/getDeviceRights")
                .then((resp) => {
                    if (resp.response.allowControlPanel && !resp.response.allowScanQR) {
                        this.$store.dispatch("authModule/setLastPath", "/")
                        this.$router.push("/")
                    } else if (!resp.response.allowControlPanel && resp.response.allowScanQR) {
                        this.$store.dispatch("authModule/setLastPath", "/qr")
                        this.$router.push("/qr")
                    } else if (resp.response.allowControlPanel && resp.response.allowScanQR) {
                        if (this.$store.getters["authModule/getLastPath"]) {
                            this.$router.push(this.$store.getters["authModule/getLastPath"])
                        } else {
                            this.choiceActiive = true
                        }
                    } else {
                        this.$store.dispatch("authModule/deleteLastPath")
                        this.toast.warning("Недостаточно прав")
                    }
                })
                .catch((err) => {
                    console.error(err)
                })
        },
        cancelLogin: function () {
            this.resetParams()
            this.$store.dispatch("authModule/cancelRequest", this.authToken)
        },
        resetParams() {
            this.autAttempt = false
            this.autAttemptCounter = 0
        }
    },
    mounted() {
        if (this.$route.query.token && !this.$store.getters["authModule/getToken"]) {
            this.authToken = this.$route.query.token
            this.login()
        }
    },
}
</script>
<style>
.custom-choice-modal {
    z-index: 9999;
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    left: 0px;
    top: 0px;
    flex-direction: column;
    place-content: center;
    background: rgba(0, 0, 0, 0.52);
    align-items: center;
}
.custom-modal-container {
    border: 3px solid rgb(59, 115, 183);
    border-radius: 10px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: auto;
    max-width: 100%;
}
.main-choice-btn {
    border-radius: 5px;
    height: 150px;
    width: 150px !important;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    padding-top: 10%;
}
.scan-login-btn {
    width: 40px !important;
    position: absolute;
    right: 3px;
    font-size: 1.3em !important;
}
.qr-code-scanner-container {
    position: relative;
    margin: 0 auto;
    max-width: 640px;
    max-height: 480px;
}
.qrcode-stream-wrapper[data-v-24c48290] {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 0;
}
.qrcode-stream-overlay[data-v-24c48290] {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.qrcode-stream-camera[data-v-24c48290] {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}
.qrcode-stream-camera--hidden[data-v-24c48290] {
    visibility: hidden;
    position: absolute;
}
</style>
