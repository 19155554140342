import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import store from '@/store'

const authGuard = function (to, from, next) {
  if (!store.getters["authModule/isAutharized"]) {
    next({ name: 'login' })
  }
  else if (store.getters["authModule/getAllowControlPanel"] && !store.getters["authModule/getAllowQR"]) {
    next()
  }
  else if (!store.getters["authModule/getAllowControlPanel"] && store.getters["authModule/getAllowQR"]) {
    next({ name: 'qr' })
  }
  else if (store.getters["authModule/getAllowControlPanel"]
    && store.getters["authModule/getAllowQR"]
    && store.getters["authModule/getLastPath"] == '/qr') {
    next({ name: 'qr' })
  } else if (store.getters["authModule/getAllowControlPanel"]) {
    next()
  } else {
    next({ name: 'login' })
  }
}

const managerAuthGuard = function (to, from, next) {
  if (!store.getters["authModule/isAutharized"] || !store.getters["authModule/getAllowQR"]) next({ name: 'login' })
  else next()
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: authGuard
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/qr',
    name: 'qr',
    component: () => import('../views/QRScanView.vue'),
    beforeEnter: managerAuthGuard
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
