<template>
    <div class="login wrapper signin-wrapper">
        <Login />
    </div>
</template>

<script>
import Login from "@/components/LoginComponent.vue";

export default {
    name: "LoginView",
    components: {
        Login,
    },
    beforeCreate: function() {
        document.body.className = 'base';
    }
};
</script>

<style>
@import "../../public/resources/css/login-style.css";
</style>
