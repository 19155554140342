<template>
    <div v-if="hasZoneScenes && selectedZoneIds.length === 0">
        <h4 class="text-muted fst-italic mt-3">
            Выберите зоны для перехода на сцену
        </h4>
    </div>
    <div v-else-if="filteredZones.length == 0">
        <h4 class="text-muted fst-italic mt-3">Нет доступных сцен</h4>
    </div>
    <div
        v-else
        class="zone-container d-flex flex-column justify-content-start align-items-center">
        <div
            class="zone-info w-100 d-flex flex-column justify-content-center align-items-center mb-4"
            v-for="zone in filteredZones"
            :key="'zone_' + zone.id">
            <span
                class="h5 text-start text-secondary fw-bold w-100 ps-3 d-block"
                >{{ zone.fullName }}</span
            >
            <button
                type="button"
                v-for="scene in zone.zoneScenes"
                @click="sendScene(scene)"
                class="send-command-btn-zone-scene py-3 mt-1"
                :key="'zone_scene_' + scene.id">
                {{ scene.name || "Название отсутствует" }}
            </button>
        </div>
    </div>
</template>
<script>
import { useToast } from "vue-toastification"
export default {
    name: "ScenesByZone",
    setup() {
        const toast = useToast()
        return { toast }
    },
    props: {
        zones: {
            type: Array,
            default: function () {
                return []
            },
        },
        selectedZoneIds: {
            type: Array,
            default: function () {
                return []
            },
        },
        selectedHardwares: {
            type: Array,
            default: function () {
                return []
            },
        },
        selectedMode: {
            type: String,
            default: function () {
                return "zone"
            },
        },
    },
    computed: {
        filteredZones() {
            return this.zones.filter(
                (x) =>
                    this.selectedZoneIds.includes(x.id) &&
                    x.zoneScenes.filter((z) => z.enabled).length > 0
            )
        },
        hasZoneScenes() {
            return (
                this.zones.filter(
                    (x) => x.zoneScenes.filter((z) => z.enabled).length > 0
                ).length > 0
            )
        },
        loading: {
            get() {
                return this.$store.getters["requestDataModule/loadingState"]
            },
            set(newValue) {
                return this.$store.dispatch(
                    "requestDataModule/setLoading",
                    newValue
                )
            },
        },
    },
    methods: {
        sendScene({ sceneNumber, zoneId }) {
            this.loading = true
            if (this.selectedMode === "zone") {
                this.$store
                    .dispatch("requestDataModule/sendZoneScene", {
                        sceneNumber,
                        zoneId,
                    })
                    .then(() => {
                        this.toast.success("Сцена успешно отправлена")
                    })
                    .finally(() => {
                        this.loading = false
                        this.$emit("command-sent", null);
                    })
            } else {
                let sceneParam = {
                    sceneNumber: sceneNumber,
                    zoneId: zoneId,
                    controllerIds: this.selectedHardwares
                        .filter(
                            (x) =>
                                x.controller != null && x.controller.zoneId == zoneId && x.controller.lsCount > 0
                        )
                        .map((x) => x.controller.id),
                    daliLogicDriverIds: this.selectedHardwares
                        .filter(
                            (x) =>
                                x.daliLogicDriver != null &&
                                x.daliLogicDriver.zoneId == zoneId &&
                                x.daliLogicDriver.lsCount > 0
                        )
                        .map((x) => x.daliLogicDriver.id),
                }
                this.$store
                    .dispatch("requestDataModule/sendLightSourcesScene", sceneParam)
                    .then(() => {
                        this.toast.success("Сцена успешно отправлена")
                    })
                    .finally(() => {
                        this.loading = false
                        this.$emit("command-sent", null);
                    })
            }
        },
    },
}
</script>

<style scoped>
.zone-container {
    height: 93%;
    display: block;
    overflow: auto;
    margin-top: 5%;
}
.send-command-btn-zone-scene {
    width: 85%;
    background-color: white;
    color: #adaeb0;
    border: solid #adaeb0 2px;
    border-radius: 7% 7% 7% 7% / 8% 10% 9% 8%;
}
.send-command-btn-zone-scene:hover {
    background-color: #3b73b7;
    color: white;
    border: solid #3b73b7 2px;
}
</style>
