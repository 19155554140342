import { DefaultAPIInstance } from "@/apiInstances";

export const MainAPI = {
    getProjectInfo(){
        const url = "/api/v1/mobile-device/design-monitoring/project-info"
        return DefaultAPIInstance.post(url, {})
    },
    getPlatforms() {
        const url = "/api/v1/mobile-device/design-monitoring/platforms"
        return DefaultAPIInstance.post(url, {})
    },
    getFloors() {
        const url = "/api/v1/mobile-device/design-monitoring/floors"
        return DefaultAPIInstance.post(url, {})
    },
    getHardwares(floorId) {
        const url = "/api/v1/mobile-device/design-monitoring/hardware"
        const data = { floorId }
        return DefaultAPIInstance.post(url, data)
    },
    getZones(){
        const url = "/api/v1/mobile-device/design-monitoring/zones"
        return DefaultAPIInstance.post(url, {})
    },
    getZoneStates(){
        const url = "/api/v1/mobile-device/design-monitoring/zone-states"
        return DefaultAPIInstance.post(url, {})
    },
    sendZonesBrightness(brightnessParams){
        const url = "/api/v1/mobile-device/design-monitoring/send-brightness-zones"
        return DefaultAPIInstance.post(url, brightnessParams)
    },
    sendLightSourcesBrightness(brightnessParams){
        const url = "/api/v1/mobile-device/design-monitoring/send-brightness-lightsources"
        return DefaultAPIInstance.post(url, brightnessParams)
    },
    sendZoneScene(scene){
        const url = "/api/v1/mobile-device/design-monitoring/send-zone-scene"
        return DefaultAPIInstance.post(url, scene)
    },
    sendLightSourcesScene(scene){
        const url = "/api/v1/mobile-device/design-monitoring/send-lightsources-scene"
        return DefaultAPIInstance.post(url, scene)
    },
    searchHardware(qrParams){
        const url = "/api/v1/mobile-device/qr-scanner/hardware/search"
        return DefaultAPIInstance.post(url, qrParams)
    },
    installController(controllerInfo){
        const url = "/api/v1/mobile-device/qr-scanner/install-controller"
        return DefaultAPIInstance.post(url, controllerInfo)
    }
}
