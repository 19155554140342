import { MainAPI } from "@/mainAPI"

export const QRScannerModule = {
    namespaced: true,

    state() {
        return {
            loading: false,
        }
    },

    getters: {
        loadingState: state => state.loading
    },

    mutations: {
        setLoadingState(state, loading) {
            state.loading = loading
        }
    },

    actions: {
        setLoading({ commit }, loading) {
            commit("setLoadingState", loading)
        },
        searchHardware(_, qrParams) {
            return MainAPI.searchHardware(qrParams).then(res => {
                if (res.data.success && !res.data.timeout) {
                    return res.data.response.result
                }
            })
                .catch(res => {
                    throw res.response || res
                })
        },
        installController(_, controllerInfo) {
            return MainAPI.installController(controllerInfo).then(res => {
                if (res.data.success && !res.data.timeout) {
                    return res.data.response
                }
            })
                .catch(res => {
                    throw res.response || res
                })
        },
    },
}