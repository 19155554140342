<template>
    <div class="control-panel">
        <nav class="navbar navbar-dark bg-dark" id="control-panel-nav">
            <div class="container-fluid">
                <img height="40" src="/resources/img/logo.png" alt="SUNRiSE" />
                <div class="floor-container">
                    <Splide
                        v-if="floorList.length"
                        :options="floorSliderOptions"
                        aria-label="SUNRiSE Floor list"
                        tag="div"
                    >
                        <SplideSlide v-for="floor in floorList" :key="'floor_' + floor.id">
                            <div
                                class="floor-select-button d-flex align-items-center justify-content-center lh-1"
                                :class="selectedFloorId == floor.id ? 'active-floor' : ''"
                                @click="setFloor(floor.id)"
                            >
                                <span>{{ floor.name }}</span>
                            </div>
                        </SplideSlide>
                    </Splide>
                    <div v-else class="no-floors-container d-flex align-items-center justify-content-between">
                        <span class="text-light">Нет доступных планов</span>
                        <button @click="logout" class="btn btn-outline-secondary" type="botton">Выход</button>
                    </div>
                </div>
            </div>
        </nav>
        <div class="row w-100">
            <div class="col-3 pt-3 ps-3">
                <div>
                    <tabs :options="{useUrlFragment: false}">
                        <tab name="Яркость/Цвет">
                            <div class="custom-tab-content-first position-relative">
                                <div class="row w-100 pt-2 ps-2">
                                    <div class="col-12 px-0 mx-0 ms-2">
                                        <div class="send-command">
                                            <button
                                                @click="sendBrightnessAndColorTemp(5)"
                                                class="send-auto-command-btn py-xl-2"
                                            >
                                                Перевести в авто
                                            </button>
                                        </div>
                                    </div>
                                    <div id="control-block" class="col-12 p-0 px-2 m-2 mt-4">
                                        <span class="ms-1 text-start" v-if="projectInfo.allowTempColorChange"
                                            >Управление цветом</span
                                        >
                                        <span
                                            v-if="projectInfo.allowTempColorChange"
                                            class="value text-start ms-1 mb-1 fw-bold fs-1"
                                            >{{ colorRangeValue }}K</span
                                        >
                                        <div
                                            v-if="projectInfo.allowTempColorChange"
                                            class="range-info-container d-flex justify-content-between w-100 mb-2"
                                        >
                                            <span class="range-info">{{ projectInfo.minColorTemp }}</span>
                                            <span class="range-info">{{ projectInfo.maxColorTemp }}</span>
                                        </div>
                                        <div id="color-control" v-if="projectInfo.allowTempColorChange">
                                            <input
                                                v-model="colorRangeValue"
                                                class="w-100 color-range"
                                                type="range"
                                                :min="projectInfo.minColorTemp"
                                                :max="projectInfo.maxColorTemp"
                                                step="1"
                                            />
                                        </div>
                                        <span
                                            class="ms-1 text-start"
                                            :class="projectInfo.allowTempColorChange ? 'mt-4' : ''"
                                            >Управление яркостью</span
                                        >
                                        <span class="value text-start ms-1 fw-bold fs-1"
                                            >{{ brightnessRequest.brightness }}%</span
                                        >
                                        <div class="range-info-container d-flex justify-content-between w-100 mb-2">
                                            <span class="range-info">0</span>
                                            <span class="range-info">100</span>
                                        </div>
                                        <div id="brightness-control">
                                            <input
                                                v-model="brightnessRequest.brightness"
                                                class="w-100 brightness-range"
                                                type="range"
                                                min="0"
                                                max="100"
                                                step="1"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12 d-flex justify-content-center mt-3 mt-xl-5">
                                        <div class="w-100">
                                            <button
                                                @click="toggleLighting(0)"
                                                class="btn btn-danger lh-1 py-xl-2 w-100 w-lg-75"
                                            >
                                                Откл.
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-12 d-flex justify-content-center mt-3 mt-xl-5">
                                        <div class="w-100">
                                            <button
                                                @click="toggleLighting(100)"
                                                class="btn btn-success lh-1 py-xl-2 w-100 w-lg-75"
                                            >
                                                Вкл.
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row send-command-btn-container">
                                    <div class="col-12">
                                        <div class="send-command">
                                            <button
                                                @click="sendBrightnessAndColorTemp(65000)"
                                                class="send-command-btn py-xl-2"
                                            >
                                                Применить
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab>
                        <tab name="Сцены">
                            <div class="custom-tab-content-second">
                                <ScenesByZone
                                    :zones="openZones"
                                    :selected-zone-ids="actualSelectedZoneIds"
                                    :selected-hardwares="selectedHardwares"
                                    :selected-mode="selectionMode"
                                    @command-sent="drawAll"
                                />
                            </div>
                        </tab>
                    </tabs>
                </div>
                <div class="bottom-btn-list w-100 d-flex justify-content-between mt-2">
                    <div>
                        <button class="btn btn-outline-primary custom-bottom-btn" type="button" @click="reloadPage">
                            <i class="bi bi-arrow-repeat"></i>
                        </button>
                        <button
                            class="btn btn-outline-primary custom-bottom-btn"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#settingsInfoModal"
                        >
                            <i class="bi bi-gear"></i>
                        </button>
                    </div>
                    <div>
                        <button class="btn btn-outline-primary custom-bottom-btn" type="button" @click="zoom('+')">
                            <i class="bi bi-plus-circle"></i>
                        </button>
                        <button class="btn btn-outline-primary custom-bottom-btn" type="button" @click="zoom('-')">
                            <i class="bi bi-dash-circle"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-9 p-0 pt-3">
                <div class="row px-1">
                    <div class="col-12 text-start border-bottom border-dark d-flex justify-content-between">
                        <div class="selected-floor-name">
                            {{ selectedFloorName }}
                            <div v-if="loading" class="spinner-border spinner-border-sm" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        <div class="selection-controls d-flex mb-2">
                            <div class="select-all-controls">
                                <button
                                    type="btn"
                                    v-if="selectionMode === 'zone'"
                                    @click="selectAllItems"
                                    class="custom-btn select-all me-2 px-4"
                                >
                                    Выбрать все зоны
                                </button>
                                <button
                                    type="btn"
                                    @click="selectAllItems"
                                    v-if="selectionMode === 'lightsource'"
                                    class="custom-btn select-all me-2 px-4"
                                >
                                    Выбрать все светильники
                                </button>
                            </div>
                            <div class="select-mode-container px-2">
                                <button
                                    type="btn"
                                    class="custom-btn select-mode-btn me-1 px-2"
                                    @click="changeSelectionMode('zone')"
                                    :class="selectionMode == 'zone' ? 'select-mode-btn-active' : ''"
                                >
                                    Зоны
                                </button>
                                <button
                                    type="btn"
                                    class="custom-btn select-mode-btn px-2"
                                    @click="changeSelectionMode('lightsource')"
                                    :class="selectionMode == 'lightsource' ? 'select-mode-btn-active' : ''"
                                >
                                    Светильники
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 ps-1 base-block" id="base-block">
                        <canvas id="designCanvas" width="900" height="500" style="border: solid transparent 1px">
                        </canvas>
                    </div>
                    <div class="col-12"></div>
                </div>
            </div>
        </div>
        <div
            class="modal fade"
            id="settingsInfoModal"
            tabindex="-1"
            aria-labelledby="settingsInfoModallLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content settings-info-modal-content">
                    <div class="modal-header">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div
                            class="settings-info-container d-flex flex-column align-items-center"
                            data-bs-toggle="modal"
                            data-bs-target="#settingsInfoModal"
                        >
                            <span><b>ID устройства:</b>{{ deviceId }}</span>
                            <button
                                v-if="allowQR"
                                class="btn btn-info mx-3 mt-2 main-choice-btn"
                                type="button"
                                @click="gotoQR"
                            >
                                <i class="bi bi-qr-code-scan h1 d-block"></i>
                                <span class="h6 text-white">Сканирование QR</span>
                            </button>
                            <button
                                @click="logout"
                                class="btn btn-primary custom-bottom-btn mx-auto mt-4"
                                type="button"
                            >
                                <i class="bi bi-box-arrow-right me-2"></i>
                                Выйти из приложения
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from "jquery"
import ScenesByZone from "@/components/ScenesByZone"
import moment from "moment"
import {useToast} from "vue-toastification"

export default {
    name: "ControlPanel",
    components: {
        ScenesByZone,
    },
    setup() {
        moment.locale("ru")
        const toast = useToast()
        return {toast}
    },
    data() {
        return {
            projectInfo: {},
            floors: [],
            floorSliderOptions: {
                perPage: 3,
                gap: "10px",
                pagination: false,
                rewindByDrag: true,
                perMove: 1,
            },
            selectedFloorId: null,
            selectedFloor: null,
            manualMode: false,
            brightnessRequest: {
                mapStreetId: null,
                brightness: 0,
                duration: null,
            },
            colorRangeValue: 3500,
            canvas: null,
            ctx: null,
            canvasOffset: null,
            offsetX: null,
            offsetY: null,
            background: new Image(),
            scale: 0,
            zoomSize: 1,
            rullerShift: 0,
            originalCanvasW: 0,
            originalCanvasH: 0,
            isDrawing: true,
            scaleMultiplier: 0.9,
            selectionMode: "zone",
            selectedLightSources: [],
            selectedLightSourceIds: [],
            selectedZoneIds: [],
            lampImg: new Image(),
            lightSensorImg: new Image(),
            lightSensorSelectedImg: new Image(),
            airSensorImg: new Image(),
            zoneStateInterval: null,
            zoneStates: [],
            zoneStatesInProcess: false,
        }
    },
    watch: {
        // eslint-disable-next-line
        selectionMode: function (oldVal, val) {
            this.drawAll()
        },
    },
    computed: {
        allowQR() {
            return this.$store.getters["authModule/getAllowQR"]
        },
        floorList() {
            return this.$store.getters["requestDataModule/floorList"]
        },
        designHardwares() {
            return this.$store.getters["requestDataModule/hardwareList"]
        },
        zones() {
            return this.$store.getters["requestDataModule/zoneList"]
        },
        openZones() {
            if (this.selectedFloorId && this.$store.getters["requestDataModule/zoneList"].length) {
                return this.$store.getters["requestDataModule/zoneList"].filter(
                    (x) => x.floorId == this.selectedFloorId
                )
            }
            return []
        },
        deviceId() {
            return this.$store.getters["authModule/getDeviceId"]
        },
        selectedFloorName() {
            let tempFloor = this.floorList.find((x) => x.id === this.selectedFloorId)
            if (tempFloor) return tempFloor.name
            return "План не выбран"
        },
        actualSelectedZoneIds() {
            if (this.selectionMode === "zone") return this.selectedZoneIds
            else {
                return this.designHardwares
                    .filter((x) => x.controller != null && this.selectedLightSourceIds.includes(x.id))
                    .map((x) => x.controller.zoneId)
                    .concat(
                        this.designHardwares
                            .filter((x) => x.daliLogicDriver != null && this.selectedLightSourceIds.includes(x.id))
                            .map((x) => x.daliLogicDriver.zoneId)
                    )
            }
        },
        selectedHardwares() {
            if (this.selectionMode === "zone") return []
            return this.designHardwares.filter(
                (x) =>
                    this.selectedLightSourceIds.includes(x.id) &&
                    ((x.controller != null && x.controller.lsCount > 0) ||
                        (x.daliLogicDriver != null && x.daliLogicDriver.lsCount > 0))
            )
        },
        loading: {
            get() {
                return this.$store.getters["requestDataModule/loadingState"]
            },
            set(newValue) {
                return this.$store.dispatch("requestDataModule/setLoading", newValue)
            },
        },
    },
    methods: {
        gotoQR() {
            if (this.zoneStateInterval != null) {
                clearInterval(this.zoneStateInterval)
            }
            this.$store.dispatch("authModule/setLastPath", "/qr")
            this.$router.push("/qr")
        },
        reloadPage() {
            window.location.reload()
        },
        selectAllItems() {
            if (this.selectionMode === "zone") {
                this.selectedLightSourceIds = []
                this.selectedZoneIds = this.openZones.filter((x) => x.zonePoints.length).map((x) => x.id)
                this.selectedLightSourceIds = this.designHardwares
                    .filter(
                        (x) =>
                            (x.controller != null &&
                                x.controller.lsCount > 0 &&
                                this.selectedZoneIds.includes(x.controller.zoneId)) ||
                            (x.daliLogicDriver != null &&
                                x.daliLogicDriver.lsCount > 0 &&
                                this.selectedZoneIds.includes(x.daliLogicDriver.zoneId))
                    )
                    .map((x) => x.id)
            } else {
                this.selectedZoneIds = []
                this.selectedLightSourceIds = this.designHardwares
                    .filter(
                        (x) =>
                            (x.controller != null && x.controller.lsCount > 0) ||
                            (x.daliLogicDriver != null && x.daliLogicDriver.lsCount > 0)
                    )
                    .map((x) => x.id)
            }
            this.drawAll()
        },
        changeSelectionMode(selectionMode) {
            this.selectionMode = selectionMode
            this.selectedZoneIds = []
            this.selectedLightSourceIds = []
        },
        getProjectInfo() {
            this.$store.dispatch("requestDataModule/getProjectInfo").then((response) => {
                this.projectInfo = response
            })
        },
        getFloors() {
            this.loading = true
            this.$store
                .dispatch("requestDataModule/getFloors")
                .then(() => {
                    if (this.floorList.length > 0) {
                        this.setFloor(this.floorList[0].id)
                    }
                })
                .catch(() => {
                    this.loading = false
                })
        },
        setFloor(floorId) {
            if (floorId === this.selectedFloorId) {
                return
            }
            this.loading = true
            this.selectedZoneIds = []
            this.selectedLightSourceIds = []
            this.selectedFloorId = floorId
            this.selectedFloor = this.floorList.find((x) => x.id == floorId)
            if (this.selectedFloor != null) {
                this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
                this.background.src = this.selectedFloor.schemeImage.content
                this.getHardwares(this.selectedFloorId)
            } else {
                this.loading = false
                this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
                this.background.src = ""
                this.selectedDesignHardware = null
                this.selectedHWId = null
                this.currentDesignHardwareIdx = -1
            }
        },
        getHardwares(floorId) {
            this.loading = true
            this.$store
                .dispatch("requestDataModule/getHardwares", floorId)
                .then(() => {
                    this.getZones()
                })
                .catch(() => {
                    this.loading = false
                })
        },
        getZones() {
            this.$store
                .dispatch("requestDataModule/getZones")
                .then(() => {
                    this.getZoneStates()
                })
                .catch(() => {
                    this.loading = false
                })
        },
        getZoneStates() {
            this.zoneStatesInProcess = true
            this.$store
                .dispatch("requestDataModule/getZoneStates")
                .then((response) => {
                    this.zoneStates = response
                    if (this.zoneStateInterval === null) {
                        this.zoneStateInterval = setInterval(() => {
                            if (!this.zoneStatesInProcess) {
                                this.getZoneStates()
                            }
                        }, 10000)
                    }
                })
                .finally(() => {
                    this.zoneStatesInProcess = false
                    this.loading = false
                    this.drawAll()
                })
        },
        changeManualMode() {
            this.manualMode = !this.manualMode
        },
        logout() {
            if (this.zoneStateInterval != null) {
                clearInterval(this.zoneStateInterval)
            }
            this.$store.dispatch("authModule/onLogout")
            this.$router.push("/login")
        },
        initCanvas() {
            this.canvas = document.getElementById("designCanvas")
            this.ctx = this.canvas.getContext("2d")
            this.canvasOffset = $("#designCanvas").offset()
            this.offsetX = this.canvasOffset.left
            this.offsetY = this.canvasOffset.top
            $("#designCanvas").mousedown((e) => {
                this.handleMouseDown(e)
            })
            // $("#designCanvas").mouseup((e) => {
            //     this.handleMouseUp(e);
            // });
            this.background.onload = () => {
                if (this.background.width < $("#base-block").width()) {
                    this.canvas.width = $("#base-block").width() + this.rullerShift
                } else {
                    this.canvas.width = this.background.width + this.selectedFloor.schemeShift * 2 + this.rullerShift
                }
                if (this.background.height < $("#base-block").height()) {
                    this.canvas.height = $("#base-block").height() + this.rullerShift
                } else {
                    this.canvas.height = this.background.height + this.selectedFloor.schemeShift * 2 + this.rullerShift
                }
                this.originalCanvasW = this.canvas.width
                this.originalCanvasH = this.canvas.height
                if (this.scale < 0) {
                    this.canvas.width += 300 * (this.scale * -1)
                    this.canvas.height += 300 * (this.scale * -1)
                }
                this.ctx.restore()
                this.ctx.save()
                this.zoomSize = Math.pow(this.scaleMultiplier, this.scale)
                this.ctx.scale(this.zoomSize, this.zoomSize)
                this.drawAll()
            }
        },
        drawAll() {
            this.isDrawing = true
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
            this.ctx.globalAlpha = parseInt(this.selectedFloor.opacityPercentage) / 100
            this.ctx.drawImage(
                this.background,
                this.selectedFloor.schemeShift + this.rullerShift,
                this.selectedFloor.schemeShift + this.rullerShift
            )
            if (this.selectedFloor.blackWhite == true) {
                let imgData = this.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height)
                let d = imgData.data
                for (let i = 0; i < d.length; i += 4) {
                    let med = (d[i] + d[i + 1] + d[i + 2]) / 3
                    d[i] = d[i + 1] = d[i + 2] = med
                }
                this.ctx.putImageData(imgData, 0, 0)
            }
            this.ctx.globalAlpha = 1
            this.ctx.textAlign = "center"
            this.ctx.textBaseline = "middle"
            if (this.selectionMode == "zone") {
                for (let i = 0; i < this.openZones.length; i++) {
                    let item = this.openZones[i]
                    this.drawZone(item, this.selectedZoneIds.includes(item.id))
                }
            }
            for (let i = 0; i < this.designHardwares.length; i++) {
                let r = this.designHardwares[i]
                this.drawHardware(r.x, r.y, r, this.selectedLightSourceIds.includes(r.id))
                if (r.controller != null) this.drawD4Data(r.x, r.y, r)
            }
        },
        drawD4Data(x, y, r) {
            this.ctx.beginPath()
            let fillStyleColor
            this.ctx.strokeStyle = r.strokeStyle
            this.ctx.lineWidth = 2
            let iconParams = {}
            if (this.zoomSize > 1) {
                iconParams = {
                    x: x - (this.selectedFloor.w * this.selectedFloor.iconSize) / 2 + 2 + this.rullerShift,
                    y: y - (this.selectedFloor.w * this.selectedFloor.iconSize) / 2 + 2 + this.rullerShift,
                    w: this.selectedFloor.w * this.selectedFloor.iconSize,
                    h: this.selectedFloor.h * this.selectedFloor.iconSize,
                }
            } else {
                iconParams = {
                    x:
                        x -
                        (this.selectedFloor.w * this.selectedFloor.iconSize) / 2 +
                        2 +
                        (this.selectedFloor.w * this.selectedFloor.iconSize -
                            (this.selectedFloor.w * this.selectedFloor.iconSize) / this.zoomSize) /
                            2 +
                        this.rullerShift,
                    y:
                        y -
                        (this.selectedFloor.w * this.selectedFloor.iconSize) / 2 +
                        2 +
                        (this.selectedFloor.h * this.selectedFloor.iconSize -
                            (this.selectedFloor.h * this.selectedFloor.iconSize) / this.zoomSize) /
                            2 +
                        this.rullerShift,
                    w: (this.selectedFloor.w * this.selectedFloor.iconSize) / this.zoomSize,
                    h: (this.selectedFloor.h * this.selectedFloor.iconSize) / this.zoomSize,
                }
            }
            if (
                r.controller.hardwareConfigurationName != null &&
                r.controller.hardwareConfigurationName.toLocaleLowerCase().includes("lightsensor") &&
                !isNaN(parseInt(r.controller.lightSensorData))
            ) {
                this.ctx.font = "bold " + (this.selectedFloor.fontSize + 2) / this.zoomSize + "px  'Roboto', sans-serif"
                this.ctx.fillStyle = "#000000"
                this.ctx.textAlign = "left"
                this.ctx.textBaseline = "middle"
                this.ctx.fillText(r.controller.lightSensorData, iconParams.x, iconParams.y - 8)
                this.ctx.fillStyle = fillStyleColor
            }
            this.ctx.closePath()
        },
        drawZone(zone, isSelected) {
            if (zone.zonePoints.length > 0) {
                if (isSelected) {
                    this.ctx.strokeStyle = "#3B74B7"
                } else {
                    this.ctx.strokeStyle = "#FFED00"
                }
                this.ctx.fillStyle = "#ffed001f"
                this.ctx.lineWidth = 2
                this.ctx.beginPath()
                this.ctx.moveTo(zone.zonePoints[0].x + this.rullerShift, zone.zonePoints[0].y + this.rullerShift)
                for (let i = 1; i < zone.zonePoints.length; i++) {
                    this.ctx.lineTo(zone.zonePoints[i].x + this.rullerShift, zone.zonePoints[i].y + this.rullerShift)
                }
                this.ctx.lineTo(zone.zonePoints[0].x + this.rullerShift, zone.zonePoints[0].y + this.rullerShift)
                this.ctx.closePath()
                this.ctx.stroke()
                this.ctx.fill()
                this.ctx.closePath()
                this.ctx.textAlign = "left"
                this.ctx.textBaseline = "top"
                if (zone.titleX != null && zone.titleY != null) {
                    this.ctx.fillStyle = "#000"
                    this.ctx.font = "bold " + this.selectedFloor.fontSize / this.zoomSize + "px Arial"
                    if (zone.titleAngle && !isNaN(parseInt(zone.titleAngle)) && parseInt(zone.titleAngle) > 0) {
                        this.ctx.textAlign = "center"
                        this.ctx.textBaseline = "middle"
                        this.ctx.save()
                        this.ctx.translate(zone.titleX + this.rullerShift, zone.titleY + this.rullerShift)
                        this.ctx.rotate((zone.titleAngle * Math.PI) / 180)
                        this.ctx.fillText((zone.description != null ? zone.description : "") + " " + zone.name, 0, 0)
                        this.ctx.restore()
                    } else {
                        this.ctx.fillText(
                            (zone.description != null ? zone.description : "") + " " + zone.name,
                            zone.titleX + this.rullerShift,
                            zone.titleY + this.rullerShift
                        )
                    }
                    let zoneState = this.zoneStates.find((x) => x.id == zone.id)

                    if (zoneState == null) {
                        this.ctx.closePath()
                        return
                    }
                    let manualBrightnessData = null
                    if (!zoneState.hasManualCommand) {
                        manualBrightnessData = "Авто"
                    } else {
                        if (zoneState.manualCommandDate != null) {
                            if (
                                moment().isAfter(
                                    moment(zoneState.manualCommandDate)
                                        .add(zoneState.manualCommandTime, "seconds")
                                        .format("YYYY-MM-DDTHH:mm:ss")
                                )
                            ) {
                                manualBrightnessData = "Авто"
                            } else {
                                manualBrightnessData =
                                    "Ручной " +
                                    (!isNaN(parseInt(zoneState.manualBrightness))
                                        ? zoneState.manualBrightness / 2 + "%"
                                        : "")
                            }
                        } else {
                            manualBrightnessData =
                                "Ручной " +
                                (!isNaN(parseInt(zoneState.manualBrightness))
                                    ? zoneState.manualBrightness / 2 + "%"
                                    : "")
                        }
                    }
                    this.ctx.fillText(
                        manualBrightnessData,
                        zone.titleX + this.rullerShift + 5,
                        zone.titleY + this.rullerShift + 15
                    )

                    let scheduleBrightnessData = null
                    if (zoneState.hasScheduleCommand) {
                        scheduleBrightnessData =
                            "График " +
                            (!isNaN(parseInt(zoneState.scheduleBrightness))
                                ? zoneState.scheduleBrightness / 2 + "%"
                                : "")
                    }
                    if (scheduleBrightnessData) {
                        this.ctx.fillText(
                            scheduleBrightnessData,
                            zone.titleX + this.rullerShift + 5,
                            zone.titleY + this.rullerShift + 30
                        )
                    }
                    this.ctx.closePath()
                }
            }
        },
        drawHardware(x, y, r, isSelected) {
            this.ctx.beginPath()
            let iconParams = {}
            if (this.zoomSize > 1) {
                iconParams = {
                    x: x - (this.selectedFloor.w * this.selectedFloor.iconSize) / 2 + 2 + this.rullerShift,
                    y: y - (this.selectedFloor.w * this.selectedFloor.iconSize) / 2 + 2 + this.rullerShift,
                    w: this.selectedFloor.w * this.selectedFloor.iconSize,
                    h: this.selectedFloor.h * this.selectedFloor.iconSize,
                }
            } else {
                iconParams = {
                    x:
                        x -
                        (this.selectedFloor.w * this.selectedFloor.iconSize) / 2 +
                        2 +
                        (this.selectedFloor.w * this.selectedFloor.iconSize -
                            (this.selectedFloor.w * this.selectedFloor.iconSize) / this.zoomSize) /
                            2 +
                        this.rullerShift,
                    y:
                        y -
                        (this.selectedFloor.w * this.selectedFloor.iconSize) / 2 +
                        2 +
                        (this.selectedFloor.h * this.selectedFloor.iconSize -
                            (this.selectedFloor.h * this.selectedFloor.iconSize) / this.zoomSize) /
                            2 +
                        this.rullerShift,
                    w: (this.selectedFloor.w * this.selectedFloor.iconSize) / this.zoomSize,
                    h: (this.selectedFloor.h * this.selectedFloor.iconSize) / this.zoomSize,
                }
            }
            if (r.controller != null && r.controller.lightSensor) {
                if (
                    r.controller.hardwareConfigurationName &&
                    r.controller.hardwareConfigurationName.toLowerCase().includes("airqualitysensor")
                ) {
                    this.ctx.drawImage(this.airSensorImg, iconParams.x, iconParams.y, iconParams.w, iconParams.h)
                } else {
                    if (isSelected) {
                        this.ctx.drawImage(
                            this.lightSensorSelectedImg,
                            iconParams.x,
                            iconParams.y,
                            iconParams.w,
                            iconParams.h
                        )
                    } else {
                        this.ctx.drawImage(this.lightSensorImg, iconParams.x, iconParams.y, iconParams.w, iconParams.h)
                    }
                }
            } else {
                if (this.zoomSize < 1) {
                    this.ctx.arc(
                        iconParams.x + iconParams.w / 2,
                        iconParams.y + iconParams.h / 2,
                        (this.selectedFloor.w * this.selectedFloor.iconSize) / 2 / this.zoomSize - 1,
                        0,
                        2 * Math.PI
                    )
                } else {
                    this.ctx.arc(
                        iconParams.x + iconParams.w / 2,
                        iconParams.y + iconParams.h / 2,
                        (this.selectedFloor.w * this.selectedFloor.iconSize) / 2 - 1,
                        0,
                        2 * Math.PI
                    )
                }
                if (isSelected) {
                    this.ctx.lineWidth = 5
                    this.ctx.strokeStyle = "#3B74B7"
                    this.ctx.stroke()
                }
                this.ctx.fillStyle = "#FFED00"
                this.ctx.fill()
                if (isSelected) {
                    this.ctx.drawImage(
                        this.lampImg,
                        iconParams.x + 5 / this.zoomSize,
                        iconParams.y + 5 / this.zoomSize,
                        iconParams.w - 10 / this.zoomSize,
                        iconParams.h - 10 / this.zoomSize
                    )
                }
            }
        },
        inside(point, vs) {
            let x = point[0],
                y = point[1]
            let inside = false
            for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
                let xi = vs[i][0],
                    yi = vs[i][1]
                let xj = vs[j][0],
                    yj = vs[j][1]

                let intersect = yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi
                if (intersect) inside = !inside
            }

            return inside
        },
        handleMouseDown(e) {
            let mousePos = this.getMousePos(this.canvas, e)
            mousePos.x = mousePos.x / this.zoomSize - this.rullerShift
            mousePos.y = mousePos.y / this.zoomSize - this.rullerShift
            if (this.selectionMode === "zone") {
                this.selectedLightSourceIds = []
                for (let i = 0; i < this.openZones.length; i++) {
                    let _candidateZone = this.openZones[i]
                    if (!_candidateZone.zonePoints.length > 0) {
                        continue
                    }
                    if (
                        this.inside(
                            [mousePos.x, mousePos.y],
                            _candidateZone.zonePoints.map((x) => {
                                return [x.x, x.y]
                            })
                        )
                    ) {
                        if (this.selectedZoneIds.includes(_candidateZone.id)) {
                            this.selectedZoneIds = this.selectedZoneIds.filter((x) => x != _candidateZone.id)
                        } else {
                            this.selectedZoneIds.push(_candidateZone.id)
                        }
                        this.selectedLightSourceIds = this.designHardwares
                            .filter(
                                (x) =>
                                    (x.controller != null &&
                                        x.controller.lsCount > 0 &&
                                        this.selectedZoneIds.includes(x.controller.zoneId)) ||
                                    (x.daliLogicDriver != null &&
                                        x.daliLogicDriver.lsCount > 0 &&
                                        this.selectedZoneIds.includes(x.daliLogicDriver.zoneId))
                            )
                            .map((x) => x.id)
                        this.drawAll()
                        break
                    }
                }
            } else if (this.selectionMode === "lightsource") {
                this.selectedZoneIds = []
                for (let i = 0; i < this.designHardwares.length; i++) {
                    let r = this.designHardwares[i]
                    if (
                        (r.controller != null && r.controller.lsCount === 0) ||
                        (r.daliLogicDriver != null && r.daliLogicDriver.lsCount === 0)
                    ) {
                        continue
                    }
                    let iconParams = {}
                    if (this.zoomSize > 1) {
                        iconParams = {
                            x: r.x - (this.selectedFloor.w * this.selectedFloor.iconSize) / 2 + 2,
                            y: r.y - (this.selectedFloor.w * this.selectedFloor.iconSize) / 2 + 2,
                            w: this.selectedFloor.w * this.selectedFloor.iconSize,
                            h: this.selectedFloor.h * this.selectedFloor.iconSize,
                        }
                    } else {
                        iconParams = {
                            x:
                                r.x -
                                (this.selectedFloor.w * this.selectedFloor.iconSize) / 2 +
                                2 +
                                (this.selectedFloor.w * this.selectedFloor.iconSize -
                                    (this.selectedFloor.w * this.selectedFloor.iconSize) / this.zoomSize) /
                                    2,
                            y:
                                r.y -
                                (this.selectedFloor.w * this.selectedFloor.iconSize) / 2 +
                                2 +
                                (this.selectedFloor.h * this.selectedFloor.iconSize -
                                    (this.selectedFloor.h * this.selectedFloor.iconSize) / this.zoomSize) /
                                    2,
                            w: (this.selectedFloor.w * this.selectedFloor.iconSize) / this.zoomSize,
                            h: (this.selectedFloor.h * this.selectedFloor.iconSize) / this.zoomSize,
                        }
                    }
                    if (
                        mousePos.x >= iconParams.x &&
                        mousePos.x <= iconParams.x + iconParams.w &&
                        mousePos.y >= iconParams.y &&
                        mousePos.y <= iconParams.y + iconParams.h
                    ) {
                        if (this.selectedLightSourceIds.includes(r.id)) {
                            this.selectedLightSourceIds = this.selectedLightSourceIds.filter((x) => x != r.id)
                        } else {
                            this.selectedLightSourceIds.push(r.id)
                        }
                        this.drawAll()
                        break
                    }
                }
            }
        },
        getMousePos(canvas, evt) {
            let rect = canvas.getBoundingClientRect()
            return {
                x: evt.clientX - rect.left,
                y: evt.clientY - rect.top,
                clientX: evt.clientX,
                clientY: evt.clientY,
            }
        },
        zoom(zoomVal) {
            this.ctx.restore()
            this.ctx.save()
            if (zoomVal == "-") {
                this.scale++
                this.zoomSize = Math.pow(this.scaleMultiplier, this.scale)
                if (this.scale < 0) {
                    this.canvas.width -= 300
                    this.canvas.height -= 300
                }
                if (this.scale == 0) {
                    this.canvas.width = this.originalCanvasW
                    this.canvas.height = this.originalCanvasH
                }
                this.redrawAll()
            } else if (zoomVal == "+") {
                this.scale--
                this.zoomSize = Math.pow(this.scaleMultiplier, this.scale)
                if (this.scale < 0) {
                    this.canvas.width += 300
                    this.canvas.height += 300
                }
                this.redrawAll()
            } else if (!isNaN(parseInt(zoomVal))) {
                this.zoomSize = Math.pow(this.scaleMultiplier, this.scale)
                this.redrawAll()
            }
        },
        redrawAll() {
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
            this.ctx.scale(this.zoomSize, this.zoomSize)
            this.drawAll()
        },
        toggleLighting(brightnessPercent) {
            if (this.selectionMode === "zone" && !this.selectedZoneIds.length) {
                this.toast.warning("Не выбраны зоны")
                return
            }
            if (this.selectionMode === "lightsource" && !this.selectedLightSourceIds.length) {
                this.toast.warning("Не выбраны светильники")
                return
            }
            this.brightnessRequest.brightness = brightnessPercent
            this.sendBrightnessAndColorTemp(65000)
        },
        sendBrightnessAndColorTemp(duration) {
            if (this.selectionMode === "zone") {
                if (!this.selectedZoneIds.length) {
                    this.toast.warning("Не выбраны зоны")
                } else {
                    let brightnessParams = {
                        brightness: this.brightnessRequest.brightness,
                        duration: duration,
                        zoneIds: this.selectedZoneIds,
                    }
                    this.loading = true
                    this.$store
                        .dispatch("requestDataModule/sendZonesBrightness", brightnessParams)
                        .then(() => {
                            this.toast.success("Яркость успешно отправлена")
                        })
                        .finally(() => {
                            this.loading = false
                            this.drawAll()
                        })
                }
            } else if (this.selectionMode === "lightsource") {
                if (!this.selectedLightSourceIds.length) {
                    this.toast.warning("Не выбраны светильники")
                } else {
                    let brightnessParams = {
                        brightness: this.brightnessRequest.brightness,
                        duration: duration,
                        controllerIds: this.designHardwares
                            .filter(
                                (x) =>
                                    this.selectedLightSourceIds.includes(x.id) &&
                                    x.controller != null &&
                                    x.controller.lsCount > 0
                            )
                            .map((x) => x.controller.id),
                        daliLogicDriverIds: this.designHardwares
                            .filter(
                                (x) =>
                                    this.selectedLightSourceIds.includes(x.id) &&
                                    x.daliLogicDriver != null &&
                                    x.daliLogicDriver.lsCount > 0
                            )
                            .map((x) => x.daliLogicDriver.id),
                    }
                    this.loading = true
                    this.$store
                        .dispatch("requestDataModule/sendLightSourcesBrightness", brightnessParams)
                        .then(() => {
                            this.toast.success("Яркость успешно отправлена")
                        })
                        .finally(() => {
                            this.loading = false
                            this.drawAll()
                        })
                }
            }
        },
    },
    mounted() {
        this.lampImg.src = "../resources/img/lamp.svg"
        this.lightSensorImg.src = "../resources/img/hardware/light-na.svg"
        this.lightSensorSelectedImg.src = "../resources/img/hardware/light-a.svg"
        this.airSensorImg.src = "../resources/img/hardware/clim-na.svg"
        this.getProjectInfo()
        this.getFloors()
        this.selectedFloorId = -1
        this.initCanvas()
    },
}
</script>
