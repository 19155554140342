<template>
    <div class="control-pannel-container">
        <ControlPanel/>
    </div>
</template>

<script>
import ControlPanel from "@/components/ControlPanel.vue";

export default {
    name: "HomeView",
    components: {
        ControlPanel,
    },
    beforeCreate: function() {
        document.body.className = 'home';
    }
};
</script>
<style>
@import "../../public/resources/css/control-panel.css";
@import "../../public/resources/css/nav-bar.css";
@import "../../public/resources/css/tabs-component.css";
@import "../../public/resources/css/brightness-control.css";
</style>
