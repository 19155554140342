import { MainAPI } from "@/mainAPI"

export const RequestDataModule = {
    namespaced: true,

    state() {
        return {
            floors: [],
            hardwares: [],
            zones: [],
            loading: false,
        }
    },

    getters: {
        floorList: state => state.floors,
        hardwareList: state => state.hardwares,
        zoneList: state => state.zones,
        loadingState: state => state.loading

    },

    mutations: {
        setFloors(state, floors) {
            state.floors = floors
        },
        setHardwares(state, hardwares) {
            state.hardwares = hardwares
        },
        setZones(state, zones) {
            state.zones = zones
        },
        setLoadingState(state, loading) {
            state.loading = loading
        }
    },

    actions: {
        setLoading({ commit }, loading) {
            commit("setLoadingState", loading)
        },
        getProjectInfo() {
            return MainAPI.getProjectInfo().then(res => {
                if (res.data.success && !res.data.timeout) {
                    return res.data.response
                }
            })
                .catch(res => {
                    console.log(res)
                    throw res.response || res
                })
        },
        getFloors({ commit }) {
            return MainAPI.getFloors().then(res => {
                if (res.data.success && !res.data.timeout) {
                    commit('setFloors', res.data.response.sort((a, b) => a.id - b.id))
                }
            })
                .catch(res => {
                    console.log(res)
                    throw res.response || res
                })
        },
        getHardwares({ commit }, floorId) {
            commit('setHardwares', [])
            return MainAPI.getHardwares(floorId).then(res => {
                if (res.data.success && !res.data.timeout) {
                    commit('setHardwares', res.data.response)
                }
            })
                .catch(res => {
                    throw res.response || res
                })
        },
        getZones({ commit }) {
            commit('setZones', [])
            return MainAPI.getZones().then(res => {
                if (res.data.success && !res.data.timeout) {
                    commit('setZones', res.data.response)
                }
            })
                .catch(res => {
                    throw res.response || res
                })
        },
        getZoneStates() {
            return MainAPI.getZoneStates().then(res => {
                if (res.data.success && !res.data.timeout) {
                    return res.data.response
                }
            })
                .catch(res => {
                    console.log(res)
                    throw res.response || res
                })
        },
        sendZonesBrightness(_, brightnessParams) {
            return MainAPI.sendZonesBrightness(brightnessParams).then(res => {
                if (res.data.success && !res.data.timeout) {
                    return res.data.response
                }
            })
                .catch(res => {
                    throw res.response || res
                })
        },
        sendLightSourcesBrightness(_, brightnessParams) {
            return MainAPI.sendLightSourcesBrightness(brightnessParams).then(res => {
                if (res.data.success && !res.data.timeout) {
                    return res.data.response
                }
            })
                .catch(res => {
                    throw res.response || res
                })
        },
        sendZoneScene(_, scene) {
            return MainAPI.sendZoneScene(scene).then(res => {
                if (res.data.success && !res.data.timeout) {
                    return res.data.response
                }
            })
                .catch(res => {
                    throw res.response || res
                })
        },
        sendLightSourcesScene(_, scene) {
            return MainAPI.sendLightSourcesScene(scene).then(res => {
                if (res.data.success && !res.data.timeout) {
                    return res.data.response
                }
            })
                .catch(res => {
                    throw res.response || res
                })
        },
    },
}